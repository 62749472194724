import { apiClient } from "@netgame/openapi";

const useGameQuest = () => {
	const { gameMode, isSweepStakes } = useSwitchMode();
	const route = useRoute();
	const isGuest = useIsGuest();

	const { data: game } = useAsyncFetch({
		path: "/rest/game/{id}{mode}/",
		method: "get",
		fetchOptions: {
			path: { id: (route.query.game ?? "") as string, mode: !isGuest.value && route.query.isMoney ? "/play" : "/" }
		},
		options: {
			transform: (data) => data?.[0],
			immediate: false,
			cached: true
		}
	});

	const { data: questPageInfo, refresh: refreshQuestPageInfo } = useGetPageQuestData();
	const { data: questInfo, refresh: refreshQuestInfo } = useGetQuestData();

	const questData = computed(() => questInfo.value?.data);
	const questDayInfo = computed(() => questData.value?.dayInfo);
	const currentTask = computed(
		() => questDayInfo.value?.tasks?.find(({ isCompleted }) => !isCompleted) || questDayInfo.value?.tasks?.[0]
	);
	const minLevelPlay = computed(
		() => currentTask.value?.[isSweepStakes.value ? "minBetForModeSweepstakes" : "minBetForModeTournamentPoints"]
	);
	const questTaskGames = computed(() => currentTask.value?.action?.details?.games || []);
	const questGameSlug = computed(() => questTaskGames.value?.[0]?.slug || "");
	const currentQuestEndTime = computed(() => questDayInfo.value?.end || "");
	const questPlayerData = computed(() => questPageInfo.value?.playerData);

	const isAllowedGameMode = computed(
		() =>
			(gameMode.value === "SweepStakes" && questData.value?.questInfo?.isSweepStakesModeAvailable) ||
			(gameMode.value === "TournamentPoints" && questData.value?.questInfo?.isTournamentPointsModeAvailable)
	);

	const isGameInTask = computed(() => {
		if (currentTask.value?.action?.type !== "gamesList" && currentTask.value?.action?.type !== "game") {
			return false;
		}
		return (
			!!currentTask.value?.action?.details?.games?.length &&
			!!currentTask.value?.action?.details?.games?.find((gameElement) => gameElement?.id === game.value?.game?.id)
		);
	});
	const taskProgress = computed(() =>
		currentTask.value?.repeat && currentTask.value?.repeat.goal !== 1
			? currentTask.value?.repeat
			: currentTask.value?.progress
	);
	const progress = computed(() => preparedProgress(currentTask.value?.isCompleted, taskProgress.value!));

	const onJoinQuest = async (questId: number) => {
		await apiClient({
			path: `/rest/quest/{questId}/subscribe/`,
			method: "post",
			parameters: { path: { questId } }
		});

		await refreshQuestPageInfo();
		await refreshQuestInfo();
	};

	return {
		questPageInfo,
		minLevelPlay,
		questGameSlug,
		questTaskGames,
		game,
		refreshQuestInfo,
		currentQuestEndTime,
		questData,
		progress,
		taskProgress,
		onJoinQuest,
		questPlayerData,
		refreshQuestPageInfo,
		currentTask,
		isAllowedGameMode,
		isGameInTask
	};
};
export default useGameQuest;
